<template>
	<div>
		<van-loading v-if="load" class="load_van" vertical></van-loading>
	</div>
</template>

<script>

import {
  getQyConfigStr,
  getJsapiTicket,
  getTicket,
  getKehuUserId
} from '@/api/test'
import AES from "../../utils/AES";
let sha1 = require('js-sha1')

export default {
	data() {
		return {
			load:false
		}
	},
	mounted(){
    this.loadWxConfig();
	},
	methods: {
    getSignatureInfo(ticket) {
      let nonceStr = 'Y7a8KkqX041bsSwT' // 随机字符串
      let newTimeStamp = Date.parse(new Date()) // 10位数的时间戳
      let url = String(location.href.split('#')[0])// 当前网页的 URL（可以为本地局域网网址），不包含 # 及其后面部分
      let verifyStr = `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${newTimeStamp}&url=${url}`
      let signature = sha1(verifyStr)

      return {
        'nonceStr': nonceStr,
        'newTimeStamp': newTimeStamp,
        'signature': signature
      };
    },
    async loadWxConfig() {

      let that = this;
      that.load = true;

      // if (!localStorage.getItem("wxLoaded")) {

      // 获取企业微信账号配置
      console.log("\n");
      console.log('=== config');
      let config = null;
      let getQyConfigStrCache = localStorage.getItem("getQyConfigStrCache");
      // getQyConfigStrCache = null;
      if (getQyConfigStrCache) {
        config = JSON.parse(getQyConfigStrCache);
      } else {
        await getQyConfigStr().then((res) => {
          if (res.code == 200) {
            config = AES.decrypt(res.data);
            localStorage.setItem("getQyConfigStrCache", JSON.stringify(config));
          }
        })
      }
      console.log(config, 'config');

      let corpid = config.corp_id;
      let agentid = config.agent_id;


      let time = Date.now()/1000;
      console.log('当前时间：', time);

        // 获取JsapiTicket
      console.log("\n");
      console.log('=== JsapiTicket');
      console.log('读取缓存：jsapiTicket');
      let jsapiTicket = null;
      let jsapiTicketCache = JSON.parse(localStorage.getItem("jsapiTicketCache"));
      // jsapiTicketCache = null;
      if (jsapiTicketCache) {
        console.log('缓存时间：jsapiTicket', time, jsapiTicketCache.expire)
        if (time < jsapiTicketCache.expire) {
          jsapiTicket = jsapiTicketCache.ticket;
        }
      }
      if (!jsapiTicket) {
        console.log('请求接口：jsapiTicket');
        await getJsapiTicket().then((res) => {
          if (res.code == 200) {
            jsapiTicket = res.data.ticket;
            localStorage.setItem("jsapiTicketCache", JSON.stringify(res.data));
          }
        })
      }
      console.log('jsapiTicketCache', jsapiTicketCache);
      console.log('jsapiTicket', jsapiTicket);

      // 获取ticket
      console.log("\n");
      console.log('=== ticket');
      console.log('读取缓存：ticket');
      let ticket = null;
      let ticketCache = JSON.parse(localStorage.getItem("ticketCache"));
      // ticketCache = null;
      if (ticketCache) {
        console.log('缓存时间：jsapiTicket', time, ticketCache.expire)
        if (time < ticketCache.expire) {
          ticket = ticketCache.ticket;
        }
      }
      if (!ticket) {
        await getTicket().then((res) =>{
          if (res.code == 200) {
            ticket = res.data.ticket;
            localStorage.setItem("ticketCache", JSON.stringify(res.data));
          }
        })
      }
      console.log('ticketCache', ticketCache);
      console.log('ticket', ticket);

      console.log("\n");
      console.log('=== wx.config');
      let signatureInfo = that.getSignatureInfo(jsapiTicket);
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        // 上线的时候记得将 debug 关掉哦！！！！！
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: corpid, // 必填，企业微信的corpid
        timestamp: signatureInfo.newTimeStamp, // 必填，生成签名的时间戳
        nonceStr: signatureInfo.nonceStr, // 必填，生成签名的随机串
        signature: signatureInfo.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: [
          'agentConfig',
        ] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });
      console.log('after wx.config')

      console.log("\n");
      console.log('=== wx.ready');
      wx.ready(function () {
        console.log('wx.ready')

        setTimeout(() => {
          let signatureInfo = that.getSignatureInfo(ticket);
          wx.agentConfig({
            debug: true,
            corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致 https://developer.work.weixin.qq.com/document/path/90665#agentid
            agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）  https://developer.work.weixin.qq.com/document/path/90665#agentid
            timestamp: signatureInfo.newTimeStamp, // 必填，生成签名的时间戳
            nonceStr: signatureInfo.nonceStr, // 必填，生成签名的随机串
            signature: signatureInfo.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法 https://developer.work.weixin.qq.com/document/path/90506
            jsApiList: [
              'getCurExternalContact',
              'openUserProfile',
              'chooseCard',
              'sendChatMessage',//分享
            ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            success: function () {

              // 回调
              wx.invoke('getCurExternalContact', {}, async function(res){

                if(res.err_msg == "getCurExternalContact:ok"){

                  // 企业微信的客户ID
                  let qy_out_user_id = res.userId;

                  // 公司系统的客户ID
                  // let outUserInfo = null;
                  // await getKehuUserId({qy_out_user_id:out_wx_user_id}).then(res => {
                  //   outUserInfo = JSON.stringify(res.data);
                  // });

                  localStorage.setItem("qy_out_user_id", qy_out_user_id);
                  // localStorage.setItem("outUserInfo", outUserInfo);
                  localStorage.setItem("loadWxConfig", 1);

                  that.$router.go(-1);
                }
              });
            },
            fail: function (res2) {
              console.log('fail===', res2)
              if (res2.errMsg.indexOf('function not exist') > -1) {
                console.log('版本过低请升级')
              }
            }
          })
        }, 0);
      });
      console.log('after wx.ready')

      wx.error(function (res) {
        console.log('wx.error', res)
      });
    },
	}
}
</script>

<style>

</style>
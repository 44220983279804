import CryptoJS from 'crypto-js';
import Buffer from "vue-buffer";
const Base64 = require('js-base64').Base64
export default {
    //随机生成指定数量的16进制key
    generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },
    
    //加密
    encrypt(key, crypted) {
        
        var iv = "123456789554125664140"; 
        iv.repeat(16);
        console.log(iv,'iv')
        var cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
        var crypted = cipher.update(data, 'utf8', 'binary');
        crypted += cipher.final('binary');
        crypted = new Buffer(crypted, 'binary').toString('base64');
        return crypted;
    },
    //解密
    decrypt(str){
        var decStr=window.atob(str);
        return JSON.parse(decStr); 
    }
 
}